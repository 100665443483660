import FormatLessonCard from "components/FormatLessonCard";
 import Title from "components/Title";
import TitleAndText from "components/TitleAndText";
import TitleWithP from "components/TitleWithP";
import CourseHead from "components/sections/CourseHead";
import ForWhoSection from "components/sections/ForWhoSection";
import FormConsultation from "components/sections/FormConsultation";
import FormOrder from "components/sections/FormOrder";
import SliderInfos from "components/sections/SliderInfos";
 import "./course.style.scss";
import { useLocaleActions } from "locale/LocaleProvider";
import { Navigate, useParams } from "react-router-dom";
import { getCourse } from "utils/functions/onload";
import { useQuery } from "react-query";
import axios from "axios";
import { CourseType } from "data/courses";
import CourseStatistics from "components/sections/CourseStatistics";
// import { FormatLessonType, LessonType } from "data";
import StudentsResults from "components/sections/StudentsResults";
import Spin from "components/Spinner";

function CoursePage() {
  const { id } = useParams();
  const { getQueryLang, getLocaleTranslate } = useLocaleActions();
  const { data, isLoading, isError } = useQuery(["course", id], () =>
    axios.get(`${process.env.REACT_APP_API_URL}api/course/get-course/${id}`)
  );

  const course: CourseType = data?.data;
  if (isError) {
    return <Navigate to="/courses" />;
  }

  if (isLoading) {
    return (
      <Spin
        loading={true}
        style={{ position: "unset", height: "100vh", zIndex: "1" }}
      ></Spin>
    );
  }

  return (
    <div className="course">
      <CourseHead course={course} />
      <CourseStatistics course={course} />
      <ForWhoSection course={course} />
     
      <FormOrder course={course}>
        {" "}
        <Title>{getLocaleTranslate("course.order.title1")}</Title> <br />
        <Title style={{ color: "#c0ff03" }}>
          {getLocaleTranslate("course.order.title2")}
        </Title>{" "}
        <p
          style={{ maxWidth: 300, padding: "2vw 0 0" }}
          className="formorder-text"
        >
          {getLocaleTranslate("course.order.text")}
        </p>
      </FormOrder>
     </div>
  );
}

export default CoursePage;
