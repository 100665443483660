import { Link, Navigate } from "react-router-dom";
import CoursePage from "./CoursePage";
import { AiFillPhone, AiFillYoutube, AiOutlineInstagram } from "react-icons/ai";
import { RiTelegramLine } from "react-icons/ri";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { IoLogoTiktok } from "react-icons/io5";

export type RouteType = {
  path: string;
  page: JSX.Element;
  id: string;
};

const routes: RouteType[] = [
   { id: "1", path: "/courses/:id", page: <CoursePage /> },
  { id: "2", path: "*", page: <Navigate to="/courses/1" replace={true} /> },
];

export default routes;
